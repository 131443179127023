@import "variables.scss";

.app-wrapper,
.app-hybrid-wrapper {
  &.--demo {
    .app-demo-message {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 40;
      overflow: hidden;
      width: 100%;
      height: $app-demo-message-height;
      background: #FEF49C;
      box-shadow: 0 0 3px #000;
      font-weight: bold;
      font-size: 2.5em;
      line-height: $app-demo-message-height;
      text-align: center;
    }

    .app-sidebar-ribbon {
      margin-top: $app-demo-message-height;
    }

    .app-sidebar {
      top: $app-demo-message-height;
    }

    .app-layout {
      .app-header {
        top: $app-demo-message-height;
      }

      .app-main {
        .app-content {
          top: $app-demo-message-height;
          padding-bottom: calc((#{$app-content-padding-narrow} / 3) + #{$app-demo-message-height});
        }
      }
    }

    .cr-grid.--full-height {
      min-height: calc(100vh - 22.2rem - #{$app-demo-message-height});

      .cr-min-width-inner {
        height: calc(100vh - 18rem - #{$app-demo-message-height});
      }
    
      &.--with-tab {
        .cr-min-width-inner {
          height: calc(100vh - 22.2rem - #{$app-demo-message-height});
        }
      }
    }
  }
}